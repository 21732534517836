<template>
  <div>
    <b-row>
      <b-col
        cols="12"
      >
        <b-card :title="$t('Checkk')">
          <label>{{ $t('search') }}</label>
          <b-row>
            <b-col
              cols="4"
            >
              <label>{{ $t('Checkk No') }}</label>
              <b-input-group>
                <b-form-input
                  id="filter-input"
                  v-model="data.filter1 "
                  type="search"
                  :placeholder="$t('Type to Search')"
                  @keyup.enter="onEnter1"
                />
              </b-input-group>
            </b-col>
            <b-col
              cols="4"
            >
              <label>{{ $t('Chassis No') }}</label>
              <b-input-group>
                <b-form-input
                  id="filter-input"
                  v-model="data.filter2 "
                  type="search"
                  :placeholder="$t('Type to Search')"
                  @keyup.enter="onEnter2"
                />
              </b-input-group>
            </b-col>
            <b-col
              cols="4"
            >
              <label>{{ $t('In all') }}</label>
              <b-input-group>
                <b-form-input
                  id="filter-input"
                  v-model="data.filter3 "
                  type="search"
                  :placeholder="$t('Type to Search')"
                  @keyup.enter="onEnter3"
                />
              </b-input-group>
            </b-col>
          </b-row>
          <br>
          <v-select
            v-model="data.checkk"
            :options="Checkks"
            :reduce="Checkks => Checkks.id"
            label="No"
          >
            <template #option="{ No , chassis_number }">
              <span> {{ No }} - {{ chassis_number }} </span>
            </template>
          </v-select>
          <small
            v-if="errors.checkk"
            class="text-danger"
          >{{ errors.checkk[0] }}</small>
        </b-card>
      </b-col>
      <b-col
        cols="12"
        md="12"
      >
        <b-card :title="$t('Checkk')">
          <b-row
            v-if="data.checkk"
          >
            <b-col
              v-for="el in ['No','chassis_number','owner_type1', 'created_year','color','fuel_type','car_type','block_reason','block1','notes']"
              :key="el"
              cols="2"
            >
              <b-form-group>
                {{ $t(el) }}
                <b-form-input
                  plaintext
                  :value="SelectedCheckk(data.checkk)[el]"
                />
              </b-form-group>
            </b-col>
          </b-row>
          <div
            v-else
            class="text-center"
          >
            البيانات غير متاحة
          </div>
        </b-card>
      </b-col>
      <b-col
        cols="12"
        md="12"
      >
        <b-row>
          <b-col
            cols="12"
          >
            <b-card :title="$t('owwner applicant')">
              <b-row
                v-if="data.checkk"
              >
                <b-col
                  v-for="el in [ 'owner', 'adress','currentAdres', 'phone', 'identify']"
                  :key="el"
                  cols="2"
                >
                  <b-form-group>
                    {{ $t(el) }}
                    <b-form-input
                      plaintext
                      :value="data1.owwner_data[el]"
                    />
                  </b-form-group>
                </b-col>
              </b-row>
              <div
                v-else
                class="text-center"
              >
                البيانات غير متاحة
              </div>
            </b-card>
          </b-col>
        </b-row>
        <b-row>
          <b-col
            cols="4"
          >
            <b-card :title="$t('car')">
              <b-row
                v-if="data.checkk"
              >
                <b-col
                  v-for="el in [ 'manufacturer', 'model']"
                  :key="el"
                  cols="6"
                >
                  <b-form-group>
                    {{ $t(el) }}
                    <b-form-input
                      plaintext
                      :value="data1.carr_data[el]"
                    />
                  </b-form-group>
                </b-col>
              </b-row>
              <div
                v-else
                class="text-center"
              >
                البيانات غير متاحة
              </div>
            </b-card>
          </b-col>
          <b-col
            cols="2"
          >
            <b-card :title="$t('plate')">
              <b-row
                v-if="data.checkk"
              >
                <b-col
                  v-for="el in [ 'number']"
                  :key="el"
                  cols="12"
                >
                  <b-form-group>
                    <b-form-input
                      plaintext
                      :value="data1.platee_data[el]"
                    />
                  </b-form-group>
                </b-col>
              </b-row>
              <div
                v-else
                class="text-center"
              >
                البيانات غير متاحة
              </div>
            </b-card>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </div>
</template>

<script>
export default {
  data() {
    return {
      options: [
        { value: 'Container', text: 'كونتينر' },
        { value: 'ROR', text: 'رورو' },
        { value: 'Land_shipping', text: 'شحن بري' },
        { value: 'Other', text: 'غير ذلك' },
      ],
      typeoptions: [
        { value: 'Normal', text: 'نظامية' },
        { value: 'Assa', text: 'تجميع' },
      ],
      fueltypeoptions: [
        { value: 'mazot', text: 'مازوت' },
        { value: 'benzin', text: 'بنزين' },
        { value: 'gaz', text: 'غاز' },
      ],
      data: {
        created_year: '',
        car: null,
        company: null,
        check_in_date: `${this.moment().format('YYYY-MM-DD')}`,
        owner_type: true,
        brake: true,
        light: true,
        combustion: true,
        fee: 0,
        total: 0,
        wheel: true,
      },
      datanew: false,
      created_year: {
        N: '1992',
        P: '1993',
        R: '1994',
        S: '1995',
        T: '1996',
        V: '1997',
        W: '1998',
        X: '1999',
        Y: '2000',
        1: '2001',
        2: '2002',
        3: '2003',
        4: '2004',
        5: '2005',
        6: '2006',
        7: '2007',
        8: '2008',
        9: '2009',
        A: '2010',
        B: '2011',
        C: '2012',
        D: '2013',
        E: '2014',
        F: '2015',
        G: '2016',
        H: '2017',
        J: '2018',
        K: '2019',
        L: '2020',
        M: '2021',
      },
      errors: [],
      fileErrors: '',

    }
  },
  computed: {
    pagination() {
      return `page=${this.currentPage}&search=${this.filter1}`
    },
    Total() {
      let totalfee = 0
      if (this.data.fee) totalfee = parseInt(this.data.fee, 10)
      return totalfee
    },
    Owwners() {
      return this.$store.getters['owwner/GetElements']
    },
    Companies() {
      return this.$store.getters['company/GetElements']
    },
    Checkks() {
      return this.$store.getters['checkk/GetElements']
    },
    Tolls() { return this.$store.getters['toll/GetElements'] },
  },
  watch: {
    // eslint-disable-next-line func-names
    'data.renewData': function (val) {
      if (val) {
        if (val === 'renew') {
          if (this.data.checkk) {
            const CheckData = this.Checkks.find(el => el.id === this.data.checkk)
            this.data.expiration_date = this.moment(CheckData.expiration_date).add(12, 'months').format('YYYY-MM-DD')
            if (this.data.carr.car_type === 'tourism' && this.data.carr.motor_power <= 1600) this.data.fee = this.Tolls.tourismOneRenew
            else if (this.data.carr.car_type === 'tourism' && this.data.carr.motor_power <= 2000) this.data.fee = this.Tolls.tourismTwoRenew
            else if (this.data.carr.car_type === 'tourism' && this.data.carr.motor_power > 2000) this.data.fee = this.Tolls.tourismThreeRenew
            else if ((this.data.carr.car_type === 'small' || this.data.carr.car_type === 'big' || this.data.carr.car_type === 'agricultural' || this.data.carr.car_type === 'work') && this.data.carr.wight <= 3000) this.data.fee = this.Tolls.otherOneRenew
            else if ((this.data.carr.car_type === 'small' || this.data.carr.car_type === 'big' || this.data.carr.car_type === 'agricultural' || this.data.carr.car_type === 'work') && this.data.carr.wight <= 11000) this.data.fee = this.Tolls.otherTwoRenew
            else if ((this.data.carr.car_type === 'small' || this.data.carr.car_type === 'big' || this.data.carr.car_type === 'agricultural' || this.data.carr.car_type === 'work') && this.data.carr.wight > 11000) this.data.fee = this.Tolls.otherThreeRenew
            else if (this.data.carr.car_type === 'microbus' && this.data.carr.passenger <= 14) this.data.fee = this.Tolls.microbusOneRenew
            else if (this.data.carr.car_type === 'microbus' && this.data.carr.passenger <= 30) this.data.fee = this.Tolls.microbusTwoRenew
            else if (this.data.carr.car_type === 'microbus' && this.data.carr.passenger > 30) this.data.fee = this.Tolls.microbusThreeRenew
            else if (this.data.carr.car_type === 'transport_ride') this.data.fee = this.Tolls.workOneRenew
            else if (this.data.carr.car_type === 'motor' && this.data.carr.axle === 2) this.data.fee = this.Tolls.motorOneRenew
            else if (this.data.carr.car_type === 'motor' && this.data.carr.axle === 3) this.data.fee = this.Tolls.motorTwoRenew
            else if (this.data.carr.car_type === 'motor' && this.data.carr.axle === 4) this.data.fee = this.Tolls.motorThreeRenew
          } else {
            alert('الرجاء اختيار الرخصة أولا')
          }
        } else if (val === 'sale') {
          this.data.fee = 0
          if (this.data.checkk) {
            if (this.data.carr.car_type === 'tourism' && this.data.carr.motor_power <= 1600) this.data.fee = this.Tolls.tourismOneBuy
            else if (this.data.carr.car_type === 'tourism' && this.data.carr.motor_power <= 2000) this.data.fee = this.Tolls.tourismTwoBuy
            else if (this.data.carr.car_type === 'tourism' && this.data.carr.motor_power > 2000) this.data.fee = this.Tolls.tourismThreeBuy
            else if ((this.data.carr.car_type === 'small' || this.data.carr.car_type === 'big' || this.data.carr.car_type === 'agricultural' || this.data.carr.car_type === 'work') && this.data.carr.wight <= 3000) this.data.fee = this.Tolls.otherOneBuy
            else if ((this.data.carr.car_type === 'small' || this.data.carr.car_type === 'big' || this.data.carr.car_type === 'agricultural' || this.data.carr.car_type === 'work') && this.data.carr.wight <= 11000) this.data.fee = this.Tolls.otherTwoBuy
            else if ((this.data.carr.car_type === 'small' || this.data.carr.car_type === 'big' || this.data.carr.car_type === 'agricultural' || this.data.carr.car_type === 'work') && this.data.carr.wight > 11000) this.data.fee = this.Tolls.otherThreeBuy
            else if (this.data.carr.car_type === 'microbus' && this.data.carr.passenger <= 14) this.data.fee = this.Tolls.microbusOneBuy
            else if (this.data.carr.car_type === 'microbus' && this.data.carr.passenger <= 30) this.data.fee = this.Tolls.microbusTwoBuy
            else if (this.data.carr.car_type === 'microbus' && this.data.carr.passenger > 30) this.data.fee = this.Tolls.microbusThreeBuy
            else if (this.data.carr.car_type === 'transport_ride') this.data.fee = this.Tolls.workOneBuy
            else if (this.data.carr.car_type === 'motor' && this.data.carr.axle === 2) this.data.fee = this.Tolls.motorOneBuy
            else if (this.data.carr.car_type === 'motor' && this.data.carr.axle === 3) this.data.fee = this.Tolls.motorTwoBuy
            else if (this.data.carr.car_type === 'motor' && this.data.carr.axle === 4) this.data.fee = this.Tolls.motorThreeBuy
          } else {
            alert('الرجاء اختيار الرخصة أولا')
          }
        }
      } else {
        this.clearData()
      }
    },
    'data.owwner': function (val) {
      if (!val) {
        this.clearData()
      }
    },
    'data.company': function (val) {
      if (!val) {
        this.clearData()
      }
    },
    'data.checkk': function (val) {
      if (val) {
        this.setData(val)
      } else {
        this.clearData()
      }
    },
    // eslint-disable-next-line func-names
  },
  created() {
    this.$store.dispatch('checkk/GetElements')
    this.$store.dispatch('company/GetElements')
    this.$store.dispatch('owwner/GetAvailableElements')
    this.$store.dispatch('toll/GetPluckedElements')
  },
  methods: {
    onEnter() {
      this.currentPage = 1
      this.getElementdata()
    },
    getElementdata() {
      this.$store.dispatch('owwner/GetAvailableElements', `page=1&search=${this.data.filter}`).then(response => {
        this.totalRows = response.data.paginate.total
        this.currentPage = response.data.paginate.current_page
        this.data.owwner = response.data.owwner[0].id
        this.data.owner = response.data.owwner[0].id
      })
    },
    onEnter1() {
      this.currentPage = 1
      this.getElementdata1()
      this.clearData()
      this.data.filter1 = ''
    },
    onEnter2() {
      this.currentPage = 1
      this.getElementdata2()
      this.clearData()
      this.data.filter2 = ''
    },
    onEnter3() {
      this.currentPage = 1
      this.getElementdata3()
      this.clearData()
      this.data.filter3 = ''
    },
    setData(val) {
      this.data.brake = true
      this.data.light = true
      this.data.combustion = true
      this.data.wheel = true
      this.data1 = this.Checkks.find(el => el.id === val)
      this.data.chassis_number = this.data1.chassis_number
      this.data.color = this.data1.color
      this.data.expiration_date = this.data1.expiration_date
      this.data.brake = this.data1.brake
      this.data.light = this.data1.light
      this.data.combustion = this.data1.combustion
      this.data.wheel = this.data1.wheel
      this.data.company = this.data1.company_id
      this.data.owwner = this.data1.owwner
      this.data.carr = this.data1.carr_data
      this.data.owner_type = this.data1.owner_type
      if (this.data1.fuel_type === 'بنزين') { this.data.fuel_type = 'benzin' } else if (this.data1.fuel_type === 'مازوت') { this.data.fuel_type = 'mazot' } else if (this.data1.fuel_type === 'غاز') { this.data.fuel_type = 'gaz' }
    },
    getElementdata1() {
      this.$store.dispatch('checkk/GetElementsByCheckkNo', `page=1&search=${this.data.filter1}`).then(response => {
        this.totalRows = response.data.paginate.total
        this.currentPage = response.data.paginate.current_page
        this.data.checkk = response.data.checkks[0].id
        this.setData(this.data.checkk)
      })
    },
    getElementdata2() {
      this.$store.dispatch('checkk/GetElementsByChassisNo', `page=1&search=${this.data.filter2}`).then(response => {
        this.totalRows = response.data.paginate.total
        this.currentPage = response.data.paginate.current_page
        this.data.checkk = response.data.checkks[0].id
        this.setData(this.data.checkk)
      })
    },
    getElementdata3() {
      this.$store.dispatch('checkk/GetElementsByAll', `page=1&search=${this.data.filter3}`).then(response => {
        this.totalRows = response.data.paginate.total
        this.currentPage = response.data.paginate.current_page
        this.data.checkk = response.data.checkks[0].id
        this.setData(this.data.checkk)
      })
    },
    clearData() {
      this.data = {
        fee: 0,
        total: 0,
        datanew: true,
        owner_type: true,
        brake: true,
        checkk: null,
        company: null,
        light: true,
        combustion: true,
        wheel: true,
        carr: null,
      }
    },
    getData(val) {
      this.$store.dispatch('checkk/GetAvailableElements', val).then(() => {
        this.data = this.Element
      })
    },
    SelectedCheckk(val) {
      return this.Checkks.find(el => el.id === val)
    },
    resetData() {
      this.data = {
        fee: 0,
        total: 0,
        datanew: true,
        owner_type: true,
        brake: true,

        light: true,
        combustion: true,
        wheel: true,
      }
      this.errors = []
    },
    setBoolean(val) {
      if (this.data[val] === undefined) { this.data[val] = false }
    },
    ExpirationDateAfter(months) {
      // const current = new Date()
      // current.setMonth(current.getMonth() + months)
      // const day = current.getDate()
      // const month = current.getMonth()
      // const year = current.getFullYear()
      // this.data.expiration_date = `${year}-${month + 1}-${day}`
      this.data.expiration_date = this.moment().add(months, 'months').format('YYYY-MM-DD')
    },
    UpdateFileError(variable) {
      this.fileErrors = variable
    },
    create() {
      this.errors = []
      this.$store.dispatch('renew/Create', this.data).then(() => {
        this.clearData()
      }).catch(error => {
        this.errors = error.response.data.errors
      })
    },
  },
}
</script>
